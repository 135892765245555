/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import 'bulma';
@import 'bulma/sass/utilities/initial-variables';

@import 'assets/variables';
@import 'assets/mixins';

@import "@angular/material/prebuilt-themes/indigo-pink.css";


// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// **Be sure that you only ever include this mixin once!**
@include mat.core();

// Define the theme.
$abb-app-primary: mat.define-palette(mat.$red-palette);
$abb-app-accent: mat.define-palette(mat.$red-palette, A200, A100, A400);
$abb-app-theme: mat.define-light-theme($abb-app-primary, $abb-app-accent);

// // Include the theme styles for only specified components.
// // @include mat-core-theme($abb-app-theme);
// // @include mat-button-theme($candy-app-theme);
@include mat.checkbox-theme($abb-app-theme);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
@include mat.all-component-themes($abb-app-theme);

@font-face {
  font-family: 'Lato';
  src: url('assets/fonts/abb-voice/ABBvoice-Regular.ttf') format('truetype')
}

$primary: #005595;
$font-stack: Lato,
Regular;

* {
  font-family: $font-stack;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 20px;
}

.button {
  font-family: $font-stack !important;
}

.body{
  background-color: #fff;
}

.mat-simple-snackbar {
  font-family: $font-stack !important;
  font-size: .7em !important;
}

div.swal2-popup {
  h2#swal2-title {
    font-family: $font-stack !important;
    font-size: 1em !important;
  }
  div#swal2-content {
    font-family: $font-stack !important;
    font-size: .8em !important;
  }
  div.swal2-actions {
    button {
      font-family: $font-stack !important;
      color: #fff !important;
      border-color: rgb(253, 246, 246) !important;
      border-radius: 30px 30px 30px 30px !important;
      background: #ff000f !important;
      font-size: .8em !important;
    }
  }
}
.cursor-pointer {
  cursor: pointer !important;
  pointer-events: visible !important;
}

.input,
.textarea,
select {
  font-family: $font-stack;
  box-shadow: none;
  border-radius: 0px;
}

.button {
  border-radius: 0px;
}

.button.bordered-red {
  color: #FF000F !important;
  border-color: #FF000F !important;
}


.button.circulo {
  color: #707070 !important;
  border-color: #707070 !important;
  border-radius: 30px 30px 30px 30px;
  font-size: 0.7em;
}

.button.carrinho-red {
  color: #fff !important;
  border-color: #FF000F !important;
  background: #FF000F;
  border-radius: 30px 30px 30px 30px;
  font-size: 0.6em;
  width: 90%;
  margin: 5px;
}

.button.borda-red {
  color: #FF000F !important;
  border-color: #FF000F !important;
    border-radius: 30px 30px 30px 30px;
  font-size: 0.6em;
  width: 90%;
  margin: 5px;
  cursor: pointer;
}

.button.carrinho-grey {
  color: #fff !important;
  border-color: #707070 !important;
  background: #707070;
  border-radius: 30px 30px 30px 30px;
  font-size: 0.6em;
  width: 90%;
  margin: 5px;
}

label {
  display: block;
  color: #707070;
  font-weight: bold;
  font-size: 1em;
}

label.mat-radio-label {
  font-weight: normal !important;
  color: #000;
}

.topBtn {
  margin-left: 100%;
  bottom: 20px;
  right: 0;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: #ff000f;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}


.button.is-danger,
.file-cta,
.mat-ink-bar,
.mat-radio-inner-circle {
  background-color: #FF000F !important;
}

.table__wrapper {
  overflow-x: auto;
}

.errorField {
  color: $error !important;
  border: 1px solid $error !important;
}

.validField {
  color: $valid !important;
  border: 1px solid $valid !important;
}

.error {
  color: $error;
  display: none;
  font-size: 14px;
  font-weight: normal;
  margin-top: 5px;

  &.show {
    display: block;
  }
}

ngx-loading {
  .backdrop {
    position: fixed !important;
    width: 100%;
    height: 100%;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;

  }

  >div:nth-child(2) {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9999999 !important;
  }

}

.minHeight650 {
  min-height: 650px;
}

.bg-default {
  margin: 0 !important;
  min-height: 650px;
  background: url(assets/imagens/bcg-blue.png) center repeat;

  &.less {
    min-height: 450px;
  }
}

.bcg-popup-blue {
  background: url(assets/imagens/bcg-blue.png) repeat;
  text-align: center;
}

.block-select {
  display: block;

  select {
    width: 100%;
    display: block;
  }
}

.back-button {
  width: 100%;
  max-width: 100px;
  background-color: #005595;
  border-color: transparent;

  span {
    color: #ffffff;
  }
}

@include create-margin-class();
@include create-margin-class('bottom');
@include create-margin-class('left');
@include create-margin-class('right');

@include create-padding-class();
@include create-padding-class('bottom');
@include create-padding-class('left');
@include create-padding-class('right');

.custom-modal {
  .modal-card-body {
    h2 {
      font-weight: 700;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 10px;
    }
  }
}

@media screen and (min-width: 769px) {
  .modal-card {
    margin: 0 !important;

    &-body {
      max-height: 60vh;
    }
  }
}

.errorList {
  margin: 10px 0;
  background: #fff;
  padding: 10px;

  h2 {
    font-weight: 700;
  }

  ul {
    list-style-type: circle;
    list-style-position: inside;
    padding: 0;
    margin: 0;

    li {
      color: #E74C3C;
      font-size: 14px;
      font-weight: normal;
      margin-top: 5px;
    }
  }
}


[hidden] {
  display: none !important;
}


.white {
  color: #ffffff;
}


@media screen and (max-width: $desktop) {
  .padding-before-popup {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.yellow {
  color: #ffc84f;
}


.spinner-three-bounce {
  position: fixed !important;
}

.swiper-pagination {
  bottom: 0px !important;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: red !important;
}

.swiper-button-next{
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAyNyA0NCc+PHBhdGggZD0nTTI3LDIyTDI3LDIyTDUsNDRsLTIuMS0yLjFMMjIuOCwyMkwyLjksMi4xTDUsMEwyNywyMkwyNywyMnonIGZpbGw9JyNmZjAwMDAnLz48L3N2Zz4=") !important
}
.swiper-button-prev {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAyNyA0NCc+PHBhdGggZD0nTTAsMjJMMjIsMGwyLjEsMi4xTDQuMiwyMmwxOS45LDE5LjlMMjIsNDRMMCwyMkwwLDIyTDAsMjJ6JyBmaWxsPScjZmYwMDAwJy8+PC9zdmc+") !important
}


