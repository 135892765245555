@import "variables";

@mixin breakpoint($breakpoint, $break: null) {
  @if $break != null {
    $mediaVal: $breakpoint;

    @each $type, $value in $breakpoints {
      @if map-has-key($value, $breakpoint) {
        $mediaVal: map-get($value, $breakpoint);
      }
    }

    @media screen and (#{$break}-width: #{$mediaVal}) {
      @content;
    }
  } @else {
    @each $type, $value in $breakpoints {
      @if map-has-key($value, $breakpoint) {
        @media screen and (#{$type}-width: #{map-get($value, $breakpoint)}) {
          @content;
        }
      }
    }
  }
}

@function lineHeight($fontSize, $lineHeight) {
  @return #{$fontSize}/#{$lineHeight};
}

@mixin bg-image($img, $format:png, $important:'') {
  background-image: url("~@images/#{$img}.#{$format}") #{$important};
}

@mixin rect($w, $h, $display:block) {
  width: $w;
  height: $h;
  display: $display;
}

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@function calculateRem($size) {
  $remSize: strip-unit($size) / strip-unit($default-font-size);
  @return $remSize;
}

@mixin arrow($direction: "down", $size: 4px, $color: "") {
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: middle;

  @if $color != "" {
    color: $color;
  }

  @if $direction == "down" {
    border-top: $size dashed;
    border-right: $size solid transparent;
    border-left: $size solid transparent;
  } @else if $direction == "left" {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size dashed;
  } @else if $direction == "up" {
    border-right: $size solid transparent;
    border-bottom: $size dashed;
    border-left: $size solid transparent;
  } @else if $direction == "right" {
    border-top: $size solid transparent;
    border-right: $size dashed;
    border-bottom: $size solid transparent;
  }
}

// keyframes mixin
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin transitions($property: all, $time: .4s, $easing: ease) {
  transition: $property $time $easing;
}

@mixin font-awesome($weight: 900) {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Free';
  font-weight: $weight;
}

@mixin no-hover($color: inherit, $bg: 0) {
  &:hover {
    text-decoration: none;
    color: $color;

    @if ($bg != 0) {
      background-color: $color;
    }
  }
}

@mixin no-outline() {
  outline: none;

  &:focus {
    box-shadow: none;
    outline: none;
  }
}

@mixin placeholders() {
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    @content;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    @content;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    @content;
  }
  &:-moz-placeholder { /* Firefox 18- */
    @content;
  }
}

/**
  $direction: 'top', 'left', 'right', 'bottom' -> default: 'up'
  $start: number -> default: 10
  $end: number -> default: 100
 */
@mixin create-margin-class($direction: 'top', $start: 10, $end: 100, $step: 5) {
  $counter: $start;

  @while ($counter <= $end) {
    .margin-#{$direction}-#{$counter} {
      margin-#{$direction}: #{$counter}px;
    }

    $counter: $counter + $step
  }
}

/**
  $direction: 'top', 'left', 'right', 'bottom' -> default: 'up'
  $start: number -> default: 10
  $end: number -> default: 100
 */
@mixin create-padding-class($direction: 'top', $start: 10, $end: 100, $step: 5) {
  $counter: $start;

  @while ($counter <= $end) {
    .padding-#{$direction}-#{$counter} {
      padding-#{$direction}: #{$counter}px;
    }

    $counter: $counter + $step
  }
}
